import {defineStore} from 'pinia'
import {ElNotification, ElMessage, ElMessageBox} from 'element-plus'
import userController from "@/controller/userController";
import menuController from "@/controller/menuController";
import formController from "@/controller/formController";

import {useUserStore} from "@/stores/user";
import {useFlowStore} from "@/stores/flow";
import {useAppStore} from "@/stores/app";
import {useMenuStore} from "@/stores/menu";
import {useDeptStore} from "@/stores/dept";
import _, {throttle} from 'lodash'


export const useFormStore = defineStore('form', {
    state: () => {
        return {
            editor: {},
            editorFormSelected: {},
            exclude: ['attachment', 'image', 'subForm', 'ocr'],
        }
    },
    actions: {
        /**
         * 拖拽规则，如子表单内不可再拖入子表单；布局组件不允许拖入行容器
         * @param e - 参考vueDraggable官方文档：https://github.com/SortableJS/vue.draggable.next?tab=readme-ov-file
         * @returns {boolean}
         */
        onDraggableMove(e) {
            let targetName = e.relatedContext.component.$attrs.name;
            let sourceTag = e.draggedContext.element.tag
            let sourceType = e.draggedContext.element.type
            if (targetName && sourceTag === targetName)
                return false;

            if (targetName && targetName === 'hbox') {
                return sourceType !== 'layout'
            } else if (targetName && targetName === 'subForm') {
                return sourceType !== 'layout' && sourceTag !== 'coding'
            }

            let columns = this.columns();
            if (sourceTag === 'coding' && columns.some(some => some.tag === 'coding' && some.id !== e.draggedContext.element.id)) {
                if (!targetName) {
                    this.throttleMessage({
                        message: '该类型控件在表单内唯一，不支持添加多个',
                        type: 'error',
                        plain: true,
                    });
                }
                return false;
            }


            return true;
        },
        throttleMessage: throttle(function (options) {
            ElMessage(options)
        }, 3000),
        title(form, row) {
            return form.setting.title.map(mapper => this.getValue(row, mapper)).filter(filter => !!filter).join(' ')
        },
        generateId(data) {
            return data.tag + "_" + parseInt(new Date().getMilliseconds() + "" + Math.ceil(Math.random() * 100000)).toString(16);
        },
        init(application_id, name, parent_id) {

            let appStore = useAppStore();

            return {
                application_id: application_id,
                type: 'form',
                name: name || '未命名表单',
                parent_id: parent_id || '',
                icon: {
                    value: parseInt(appStore.randomInt(1, appStore.iconCount)),
                    front: '#FFFFFF',
                    background: '#EA4E27',
                },
                elements: [],
                setting: {
                    title: [],
                    //开启评论
                    isReview: true,
                    //开启提醒
                    isRemind: true,
                    isLog: true,
                    isPC: true,
                    isMobile: true,
                    style: {
                        PC: {
                            layout: 'top', //  top / left
                            title: 'bold', //  bold / normal
                        },
                        mobile: {
                            layout: 'top', //  top / left
                            title: 'bold', //  bold / normal
                        },
                    },
                    list: {
                        fields: [],
                        buttonSetting: [
                            {name: '新增', enable: true},
                            {name: '导入', enable: true},
                            {name: '导出', enable: true},
                            {name: '打印二维码', enable: true},
                            {name: '删除', enable: true},
                        ],
                        filters: [],
                    },
                    form: {
                        fields: [
                            {
                                title: '新增数据权限',
                                system: true,
                                description: '默认全员使用此组的新增数据字段权限',
                                role: [],
                                fields: {},
                            },
                            {
                                title: '查看编辑数据权限',
                                system: true,
                                description: '用户角色未分配自定义权限组时，默认使用此组的数据查看编辑权限',
                                role: [],
                                fields: {},
                            },
                        ],
                        summary: [],
                        validate: [],
                        trigger: [],
                        ocr: [],
                    },

                },
                workFlow: {
                    enable: false,
                    nodeList: [],
                    lineList: [],
                    setting: {
                        //允许发起人撤回
                        isRevocation: false,
                        //重复申请，自动通过
                        isRepeatedApprovalAutomaticallyPasses: false,
                        //复用签名
                        isMultiplexSignature: true,
                        //上传签名
                        isUploadSignature: true
                    }
                },
            }
        },
        //初始化字段规则operation属性
        initOperation(columns, operation) {
            let operationNew = {};
            columns.filter(filter => !filter.id.startsWith('_property')).forEach(item => {
                operationNew[item.id] = {};
                operationNew[item.id].isRead = operation && operation[item.id] ? operation[item.id].isRead : true;
                operationNew[item.id].isWrite = operation && operation[item.id] ? operation[item.id].isWrite : false;
                operationNew[item.id].isRequired = operation && operation[item.id] ? operation[item.id].isRequired : false;

                if (item.id.startsWith('_property')) {
                    delete operationNew[item.id].isWrite;
                    delete operationNew[item.id].isRequired;
                }
            });

            return operationNew;
        },
        setEditor(form) {
            this.editor = form;
        },
        setEditorFormSelected(obj) {
            this.editorFormSelected = obj;
        },
        submit(form, callback) {

            if (!form) {
                form = this.editor;
            }


            if (!form.name) {
                ElMessage({
                    message: '没有设置表单名称',
                    type: 'error',
                    plain: true,
                })
                return;
            }


            if (form.workFlow && form.workFlow.enable) {

                if (!form.workFlow.nodeList.some(some => some.type === 'start')) {
                    ElMessage({
                        message: '请设置【开始节点】',
                        type: 'error',
                        plain: true,
                    })

                    return;
                }

                if (!form.workFlow.nodeList.some(some => some.type === 'sponsor')) {
                    ElMessage({
                        message: '请设置【发起节点】',
                        type: 'error',
                        plain: true,
                    })

                    return;
                }

                if (!form.workFlow.nodeList.some(some => some.type === 'end')) {
                    ElMessage({
                        message: '请设置【结束节点】',
                        type: 'error',
                        plain: true,
                    })

                    return;
                }
            }


            menuController.post(form).then(res => {

                if (res.code === 200) {
                    if (form && typeof res.data === 'string') {
                        form.id = res.data
                    }
                    if (callback) {
                        callback()
                    }
                } else {

                    ElMessageBox.alert(
                        res.message,
                        '异常',
                    )
                }

            });
        },
        //根据表单数据结构模拟表单数据
        simulateData(form) {
            if(!form)
                return;

            let data = {'创建时间': '--', '提交人': '模拟数据', '流程状态': '--'}
            form.elements.filter(filter => {
                if (filter.tag === 'hbox') {
                    if (filter.list1.length > 0) {
                        let temp = filter.list1[0]
                        data[temp.label] = '--';
                    }

                    if (filter.list2.length > 0) {
                        let temp = filter.list2[0]
                        data[temp.label] = '--';
                    }

                    if (filter.list3.length > 0) {
                        let temp = filter.list3[0]
                        data[temp.label] = '--';
                    }

                    if (filter.list4.length > 0) {
                        let temp = filter.list4[0]
                        data[temp.label] = '--';
                    }
                }
                return filter.type !== 'layout';
            }).forEach(item => data[item.label] = '--');
            return data;
        },
        //初始化表单数据结构
        initData(form) {

            let data = {}
            form.elements.filter(filter => {
                if (filter.tag === 'hbox') {
                    if (filter.list1.length > 0) {
                        let temp = filter.list1[0]
                        data[temp.id] = temp.value;
                    }
                    if (filter.list2.length > 0) {
                        let temp = filter.list2[0]
                        data[temp.id] = temp.value;
                    }
                    if (filter.list3.length > 0) {
                        let temp = filter.list3[0]
                        data[temp.id] = temp.value;
                    }
                    if (filter.list4.length > 0) {
                        let temp = filter.list4[0]
                        data[temp.id] = temp.value;
                    }
                }
                return filter.type !== 'layout';
            }).forEach(item => {
                data[item.id] = item.value;
            });

            return data;
        },
        columnsFlatMap(form) {
            return this.columns(form).flatMap(column => {
                if (column.children && column.children.length > 0) {

                    let subForm = column.children.map(child => {
                        return {
                            id: child.id,
                            parent: column.id,
                            label: column.label + "." + child.label,
                            tag: child.tag,
                            attrs: child.attrs,
                            children: child.children
                        }
                    });

                    subForm.push(column);
                    return subForm;

                } else {
                    return [column];
                }
            })
        },
        columns(form) {

            if (!form) {
                if (this.editor) {
                    form = this.editor;
                } else {
                    throw new Error('formStore.columns -> form is null');
                }
            }

            let columns = []
            form.elements.filter(filter => filter.type !== 'layout' || filter.tag === 'hbox').forEach(item => {
                if (item.tag === 'hbox') {
                    [...item.list1, ...item.list2, ...item.list3, ...item.list4].forEach(component => {
                        columns.push({
                            id: component.id,
                            label: component.label,
                            tag: component.tag,
                            attrs: component.attrs,
                            children: component.children
                        })
                    })

                } else {
                    columns.push({
                        id: item.id,
                        label: item.label,
                        tag: item.tag,
                        attrs: item.attrs,
                        //兼顾子表单
                        children: item.children
                    })
                }
            });

            //创建时间加上attrs.format属性，是为了simpleCondition组件可以正常读取
            columns.push({
                id: "_property.create_time", label: '创建时间', tag: 'date', attrs: {
                    format: 'YYYY-MM-DD'
                }
            })
            columns.push({
                id: "_property.submitter_id",
                label: '提交人',
                tag: 'people'
            });

            if (form.workFlow.enable) {
                columns.push({
                    id: "_property.workFlow_status",
                    label: '流程状态',
                    tag: 'input'
                });
            }


            return columns;
        },
        //可编辑的字段 （忽略类型: 图片、 附件 、 布局等 ）
        columnsEditable(form) {
            if (!form) {
                if (this.editor) {
                    form = this.editor;
                } else {
                    throw new Error('formStore.columns -> form is null');
                }
            }

            return this.columns(form).filter(filter => filter.tag !== 'image' && filter.tag !== 'attachment');
        },
        createGroup(application_id, callback) {
            ElMessageBox.prompt('请输入分组名称', '新建分组', {
                confirmButtonText: '提交',
                cancelButtonText: '取消',
            })
                .then(({value}) => {
                    if (value) {
                        this.submit({
                            type: 'group',
                            name: value,
                            application_id
                        }, callback);
                    }
                })
                .catch(() => {
                })
        },
        rename(data, callback) {
            ElMessageBox.prompt(data.name, '修改名称', {
                confirmButtonText: '提交',
                cancelButtonText: '取消',
                inputValue: data.name
            })
                .then(({value}) => {
                    if (value && value !== data.name) {
                        menuController.rename(data.id, value).then(() => {
                            if (callback) callback();
                        })
                    }
                })
                .catch(() => {
                })
        },
        sort(ids, callback) {
            menuController.sort(JSON.stringify(ids)).then(res => {
                if (callback) callback();
            })
        },
        delete(data, callback) {
            let message = "";
            switch (data.type) {
                case 'form':
                    message = '表单及所有业务数据将全部删除，请谨慎操作';
                    break;
                case 'group':
                    message = '仅删除当前分组，分组下的内容将自动移出，不会同步删除';
                    break;
                case 'link':
                    message = '外部链接将被删除，请谨慎操作';
                    break;
                case 'document':
                    message = '自定义页面将被删除，请谨慎操作';
                    break;
                case 'report':
                    message = '报表将被删除，请谨慎操作';
                    break;
                default:
                    throw new Error(`不支持的类型:${data.type}`)
            }
            ElMessageBox.confirm(
                `<b style="color: #303133">确定要删除 【${data.name}】吗?</b> <br> <div style="color: #A8ABB1;font-size: 13px">${message}</div>`,
                '警告',
                {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    dangerouslyUseHTMLString: true,
                    type: 'warning',
                    autofocus: false
                }
            ).then(() => {
                menuController.delete(data.id).then(() => {
                    if (callback) callback();
                });
            }).catch(() => {
            })
        },
        moveTo(source, to, callback) {
            menuController.moveTo(source, to).then(() => {
                if (callback) callback();
            });
        },
        async temporaryStorage(form_id, data) {
            return await formController.temporaryStorage(form_id, data);
        },
        async postRow(form_id, data) {
            return await formController.post(form_id, data);
        },
        deleteRow(formId, dataId, callback) {

            ElMessageBox.confirm(
                '该数据删除后将无法恢复，请谨慎操作',
                '确定删除该条数据',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    icon: 'QuestionFilled',
                    autofocus: false
                }
            )
                .then(() => {
                    formController.delete(formId, dataId).then(() => {
                        if (callback) callback()
                    })
                })
                .catch(() => {
                })

        },
        updateRow(formId, data) {
            return formController.put(formId, data);
        },
        list(form_id, currentPage, pageSize, sorts, conditions, keyword) {
            return formController.list({
                form_id, currentPage, pageSize, sorts, conditions, keyword
            });
        },
        async findById(form_id, data_id) {
            return await formController.findById(form_id, data_id);
        },
        getValue(row, key) {
            return row[key]
        },
        onDeleteElement(element, parent) {
            let arr = [element.id];
            if (element.tag === 'hbox') {
                if (element.list1.length > 0) arr.push(element.list1[0].id)
                if (element.list2.length > 0) arr.push(element.list2[0].id)
                if (element.list3.length > 0) arr.push(element.list3[0].id)
                if (element.list4.length > 0) arr.push(element.list4[0].id)
            }

            // 处理删除表单element造成的影响
            arr.forEach(id => this.onDeleteElementCauseInfluence(id));

            if (parent) {
                parent.splice(parent.indexOf(element), 1);
            } else {
                this.editor.elements.splice(this.editor.elements.indexOf(element), 1);
            }

        },
        /**
         * 处理删除表单element造成的影响
         * @param id - element.id
         */
        onDeleteElementCauseInfluence(id) {
            //删除 对 表单标题 造成的影响
            this.editor.setting.title = this.editor.setting.title.filter(filter => filter !== id);
            //删除 对 列表显示 造成的影响
            this.editor.setting.list.fields = this.editor.setting.list.fields.filter(filter => filter !== id);
            //删除 对 过滤条件 造成的影响
            this.editor.setting.list.filters = this.editor.setting.list.filters.filter(filter => filter.id !== id);
            //删除 对 流程字段 造成的影响
            this.editor.workFlow.nodeList.forEach(node => {
                if (node.attrs.operation) {
                    let keys = Object.keys(node.attrs.operation)
                    if (keys.indexOf(id) !== -1) {
                        delete node.attrs.operation[id];
                    }
                }
            })
            //删除 对 字段权限 造成的影响
            this.editor.setting.form.fields.forEach(field => delete field.fields[id])
            //删除 对 数据摘要 造成的影响
            this.editor.setting.form.summary = this.editor.setting.form.summary.filter(filter => filter !== id);
            //删除 对 打印模版 造成的影响
           if( this.editor.setting.form.printers){
               this.editor.setting.form.printers.forEach(printer => {
                   printer.template.forEach(row => {
                       row.forEach(col => {
                           if (col.length > 0) {
                               console.log(col[0])
                               if (col[0].id === id) {
                                   col.splice(0, 1)
                               } else if (col[0].tag === 'subForm') {
                                   col[0].children = col[0].children.filter(filter => filter.id !== id)
                               }
                           }
                       })
                   })
               })
           }



        },
        onAddElement(element) {

            //判断添加到表单中 （可能直接添加到hbox中）
            if (this.editor.elements.some(item => {
                if (item.tag === 'hbox') {
                    return [
                        ...item.list1.map(mapper => mapper.id),
                        ...item.list2.map(mapper => mapper.id),
                        ...item.list3.map(mapper => mapper.id),
                        ...item.list4.map(mapper => mapper.id)].includes(element.id);
                } else {
                    return item.id === element.id;
                }
            })) {
                if (element.type === 'basic') {

                    this.editor.setting.list.fields.push(element.id);
                    this.editor.setting.form.fields.forEach(field => {
                        field.fields[element.id] = {
                            isRead: true,
                            isWrite: true,
                            isRequired: false,
                        };

                    })
                    //添加元素 对 流程字段 造成的影响
                    this.editor.workFlow.nodeList.forEach(node => {
                        if (node.attrs.operation) {
                            let keys = Object.keys(node.attrs.operation)
                            if (keys.indexOf(element.id) === -1) {
                                let flowStore = useFlowStore();
                                node.attrs.operation = flowStore.initOperation(node, this.columns(), node.attrs.operation);
                            }
                        }
                    })

                } else if (element.tag === 'hbox') {
                    let ids = [...element.list1.map(mapper => mapper.id), ...element.list2.map(mapper => mapper.id),
                        ...element.list3.map(mapper => mapper.id), ...element.list4.map(mapper => mapper.id)];
                    ids.forEach(id => {

                        //新增字段对列表设计影响
                        if (this.editor.setting.list.fields.indexOf(id) === -1)
                            this.editor.setting.list.fields.push(id);

                        //新增字段对字段权限影响
                        this.editor.setting.form.fields.forEach(field => {
                            if (field.fields[element.id] === undefined) {
                                field.fields[element.id] = {
                                    isRead: true,
                                    isWrite: true,
                                    isRequired: false,
                                };
                            }
                        })

                        //添加元素 对 流程字段 造成的影响
                        this.editor.workFlow.nodeList.forEach(node => {
                            if (node.attrs.operation) {
                                let keys = Object.keys(node.attrs.operation)
                                if (keys.indexOf(element.id) === -1) {
                                    let flowStore = useFlowStore();
                                    node.attrs.operation = flowStore.initOperation(node, this.columns(), node.attrs.operation);
                                }
                            }
                        })
                    })
                }
            }
        },
        async listUser() {
            return await formController.listUser({company: useUserStore().getCompanySelected});
        },
        referenceForm(form_id, keyword) {
            return formController.referenceForm({
                form_id, keyword
            });
        },
        /**
         * 关联表单填充数据
         * @param sourceData - 关联表单数据
         * @param targetData - 当前表单数据
         * @param element - 关联表单组件
         * @param toForm
         * @param selectSubFormIndex - 如果数据源是表单，selectSubFormIndex代表子表的行数
         */
        async referenceFormFill(sourceData, targetData, element, toForm, selectSubFormIndex) {

            let main = element.attrs.fillRole.main;
            let subForms = element.attrs.fillRole.subForms;


            let toColumns = this.columnsFlatMap(toForm)
            await useMenuStore().findById(element.attrs.form_id).then(res => {
                let fromColumns = this.columnsFlatMap(res.data);
                if (element.attrs.fillRole.mode && element.attrs.fillRole.mode !== 'default' && Number.isInteger(selectSubFormIndex)) {
                    sourceData = {...sourceData[element.attrs.fillRole.mode][selectSubFormIndex], ...sourceData};
                }

                if (element.attrs.parent === 'subForm') {
                    main.forEach(item => {

                        if (sourceData[item.from] !== undefined) {
                            try {
                                targetData[item.to.columnId] = sourceData[item.from]
                            } catch (e) {
                            }
                        }
                    })
                } else {
                    main.forEach(item => {
                        if (sourceData[item.from] !== undefined) {
                            try {

                                let fromColumn = fromColumns.find(find => find.id === item.from);
                                let toColumn = toColumns.find(find => find.id === item.to);


                                this.getFillValue(fromColumn, toColumn, sourceData[item.from]).then(res => {
                                    targetData[item.to] = res.data;
                                })

                            } catch (e) {
                                console.error(e)
                            }
                        }
                    })
                    subForms.forEach(subForm => {
                        if (sourceData[subForm.id] !== undefined) {
                            //forms
                            let forms = {}
                            try {
                                subForm.list.forEach(correspondence => forms[correspondence.to.formId] = {})
                                Object.keys(forms).forEach(key => targetData[key].splice(0, targetData[key].length))
                                sourceData[subForm.id].forEach(row => {
                                    //初始化
                                    Object.keys(forms).forEach(key => forms[key] = {
                                        id: parseInt(new Date().getMilliseconds() + "" + Math.ceil(Math.random() * 100000)).toString(16)
                                    });
                                    subForm.list.forEach(correspondence => forms[correspondence.to.formId][correspondence.to.columnId] = row[correspondence.from])
                                    Object.keys(forms).forEach(key => targetData[key].push(forms[key]))
                                })
                            } catch (e) {

                            }
                        }
                    })
                }

            })


        },
        /**
         * 获取表单填充值，支持类型转换
         * @param fromColumn
         * @param toColumn
         * @param fromValue
         * @returns {Promise<{data: *}>|*|Promise<{data}>|Promise<unknown>|string}
         */
        getFillValue(fromColumn, toColumn, fromValue) {

            if (toColumn.tag === 'input' || toColumn.tag === 'textarea') {

                if (!fromValue || fromValue.length < 1) {
                    return '';
                }

                if (fromColumn.tag === 'referenceForm') {
                    return this.findTitleById(fromColumn.attrs.form_id, fromValue);
                } else if (fromColumn.tag === 'referenceFormSelect') {
                    return this.findColumnsByIds(fromColumn.attrs.form_id, fromValue, fromColumn.attrs.show_columns).then(res => {
                        return {data: res.data.join(",")};
                    });
                } else if (fromColumn.tag === 'people') {
                    let userStore = useUserStore();
                    if (Array.isArray(fromValue)) {
                        return userStore.findByIds(fromValue).then(response => {
                            return {data: response.data.map(mapper => mapper.real_name).join(",") || ''}
                        });

                    } else {
                        return userStore.findById(fromValue).then(response => {
                            return {data: response.data.real_name || ''}
                        });
                    }

                } else if (fromColumn.tag === 'dept') {
                    let deptStore = useDeptStore();
                    if (Array.isArray(fromValue)) {
                        return deptStore.findByIds(fromValue).then(response => {
                            return {data: response.data.map(mapper => mapper.name).join(",") || ''}
                        });

                    } else {
                        return deptStore.findById(fromValue).then(response => {
                            return {data: response.data.name || ''}
                        });
                    }
                }
            }

            return new Promise((resolve, reject) => {
                resolve({data: fromValue})
            });
        },
        async findTitleById(form_id, data_id) {
            return await formController.findTitleById({form_id, data_id});
        },
        async findTitleByIds(form_id, data_ids) {
            return await formController.findTitleByIds({form_id, data_ids: data_ids.join(",")});
        },
        async findColumnsById(form_id, data_id, columns) {
            return await formController.findColumnsById({form_id, data_id, columns: columns.toString()});
        },
        async findColumnsByIds(form_id, data_ids, columns) {
            return await formController.findColumnsByIds({
                form_id,
                data_ids: data_ids.join(','),
                columns: columns.toString()
            });
        },
        getCoding(form_id) {
            return formController.getCoding({form_id});
        },
        setCoding(form_id, coding) {
            return formController.setCoding({form_id, coding});
        },
        generateCoding(form_id, element) {
            return formController.generateCoding(form_id, element);
        },
        filterColumnsBySource(source, columns0) {

            let columns = _.cloneDeep(columns0);

            if (!source || source === 'default') {

                return columns.map(item => {
                    // item.children = [];
                    return item;
                });
                ;
            } else {
                return columns.map(item => {
                    if (item.id !== source) {
                        item.children = [];
                    }
                    return item;
                });
            }

        },
        getComment(form_id, data_id) {
            return formController.getComment(form_id, data_id);
        },
        getTimeline(form_id, data_id) {
            return formController.getTimeline(form_id, data_id);
        },
        async getWorkFlow(form_id, data_id) {
            return await formController.getWorkFlow(form_id, data_id);
        },
        /** 设置中排除在外的element */
        isNotExcludeElement(element) {
            return !this.exclude.includes(element.tag);
        },
        property(formId, dataId, params) {
            return formController.property(formId, dataId, params)
        },
        query(params) {
            return formController.list(params);
        },
        statistics(formId, property, conditions) {
            return formController.statistics(formId, {property, conditions});
        }
    },
    getters: {
        getEditorFormSelected() {
            return this.editorFormSelected;
        },
    },
    persist: {
        enabled: false
    }
})